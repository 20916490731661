<template>
  <div
    class="ui-chat-bubble"
    :class="{'--local': isMe}"
  >
    <ui-avatar
      v-if="showAvatar"
      class="bubble-avatar"
      :title="`${person.firstName} ${person.lastName}`"
      :src="person.avatar"
      :size="32"
    ></ui-avatar>

    <div class="bubble-contents">

      <slot name="bubble-before"></slot>

      <div class="bubble-body">
        <div class="bubble-author-name">{{ person.firstName }} {{ person.lastName }}</div>

        <div
          v-if="replyTo"
          class="bubble-reply-to"
        >
          <div class="reply-author-name">{{ replyTo.person.firstName }} {{ replyTo.person.lastName }}</div>
          <div
            class="reply-text"
            v-html="replyTo.text"
          ></div>
        </div>

        <div
          class="bubble-text"
          v-html="text"
        ></div>
      </div>
      <div
        v-if="showDate"
        class="bubble-date"
      >{{ $ts(date, 'date') }}</div>
    </div>

    <div
      class="bubble-options"
      v-if="$slots.options || $scopedSlots.options"
    >
      <slot name="options"></slot>
    </div>
  </div>
</template>

<script>
import useI18n from '@/modules/i18n/mixins/useI18n.js';
import { UiAvatar } from '@/modules/ui/components';

export default {
  name: 'ui-chat-bubble',
  mixins: [useI18n],
  components: { UiAvatar },

  props: {
    isMe: {
      type: Boolean,
      required: false,
      default: false,
    },

    person: {
      type: Object,
      required: true,
    },

    date: {
      type: [String, Number],
      required: true,
    },

    text: {
      type: String,
      required: true,
    },

    replyTo: {
      type: Object,
      required: false,
      default: null,
    },

    showAvatar: {
      type: Boolean,
      required: false,
      default: true,
    },

    showDate: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>

<style lang="scss">
.ui-chat-bubble {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  padding: 0 56px 0 0;

  .bubble-avatar {
    border-radius: 50%;
    margin: 0 12px 16px 12px;
    min-width: 32px;
  }

  .bubble-contents {
    .bubble-author-name {
      font-size: 12px;
      font-weight: bold;
      color: var(--ui-color-primary);
    }

    .bubble-body {
      background-color: rgba(0, 0, 255, 0.03);
      padding: 8px 12px;
      border-radius: 12px;
      border-bottom-left-radius: 0;
      font-size: 15px;
    }

    .bubble-text {
      word-break: break-word;
    }

    .bubble-date {
      font-size: 12px;
      opacity: 0.6;
    }
  }

  .bubble-options {
    align-self: flex-start;
    margin: 0 4px;
  }

  .bubble-reply-to {
    margin: 8px 0 6px 0;
    border-left: 5px solid var(--ui-color-primary);
    padding: var(--ui-padding);
    border-radius: var(--ui-radius);
    background-color: rgba(0, 0, 0, 0.03);

    .reply-author-name {
      font-size: 12px;
      font-weight: bold;
    }

    .reply-text {
      font-size: 0.8em;
      word-break: break-word;
    }
  }

  &.--local {
    justify-content: flex-end;
    padding: 0 8px 0 56px;

    .bubble-reply-to {
      border-color: #fff;
      background-color: rgba(255, 255, 255, 0.1);
    }

    .bubble-avatar {
      display: none;
    }

    .bubble-options {
      order: 1;
    }

    .bubble-contents {
      order: 2;
    }

    .bubble-author-name {
      display: none;
    }

    .bubble-body {
      background-color: var(--ui-color-primary);
      border-radius: 12px;
      border-bottom-right-radius: 0;
      color: #fff;
    }

    .bubble-date {
      text-align: right;
    }
  }
}
</style>